.experience__container {
  display: grid;
  grid-template-rows: 1fr;
  justify-content: center;
  gap: 3rem;
}

.experience__container > div {
  justify-self: center;
  width: fit-content;
  background: var(--color-primary);
  color: var(--color-white);
  /* border: 1px solid var(--color-primary-variant); */
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  padding: 2.4rem 4rem;
  text-align: center;
  cursor: default;
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  color: var(--color-white);
}

/* .experience__languages > div {
  display: flex;
  justify-self: center;
  row-gap: 2rem;
} */

.experience__content {
  display: flex;
  justify-content: space-around;
  gap: 2rem;
}

.experience__details {
  /* display: flex;
  justify-self: center;
  align-items: center; */
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  /* gap: 1rem; */
  transition: var(--transition);
}
.experience__details:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  /* color: var(--color-bg-variant); */
  background: var(--color-bg-variant);
  transform: scale(1.3);
}

.experience__details-icon {
  width: 6rem;
  height: 6rem;
  margin-top: 6px;
  /* font-size: 3rem; */
  /* color: var(--color-primary); */
}

/* Responsividad (Medium Devices) */
@media screen and (max-width:1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }

  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .experience__content {
    padding: 1rem;
  }
}

/* Responsividad (Small Devices) */
@media screen and (max-width:600px) {
  .experience__container {
    gap: 1rem;
  }

  .experience__container > div {
    width: 100%;
    padding: 2rem 1rem;
  }
}