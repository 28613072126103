.container.contact__container {
  width: 58%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
}

.contact__options {
  display: grid;
  flex-direction: column;
  gap: 1.2rem;
}

.contact__option {
  padding: 1.2rem;
  border-radius: 1.2rem;
  background: transparent;
  border: 1px solid var(--color-primary-variant);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 3px;
  text-align: center;
  transition: var(--transition);
}

.contact__option:hover {
  color: var(--color-white);
  background: var(--color-bg-variant);
  border-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: default;
  transform: scale(1.1);
}

.contact__option-icon {
  font-size: 2.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 1.2rem;
}

.contact__option h5 {
  color: var(--color-light);
}

/* Form */
form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input, textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
}

/* Responsividad (Medium Devices) */
@media screen and (max-width:1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/* Responsividad (Small Devices) */
@media screen and (max-width:600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }
}