.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  justify-content: center;
}

.portfolio__item {
  width: 26rem;
  /* height: fit-content; */
  background: transparent;
  border: 1px solid var(--color-primary-variant);
  border-radius: 2rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 3px;
  padding: 1.2rem;
  transition: var(--transition);
}

.portfolio__item:hover {
  color: var(--color-white);
  background: var(--color-bg-variant);
  border-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: scale(1.1);
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  font-size: 1.7rem;
  text-align: center;
  margin: 1.2rem 0 1rem;
}

.langs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5rem;
}

.langs img {
  width: 25%;
}

.techs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.techs h4 {
  grid-column: 1/4;
}

.techs img {
  width: 70%;
  border-radius: 1rem;
  padding: 0.2rem;
  transition: var(--transition);
}
.techs img:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  background: var(--color-primary);
  transform: scale(1.2);
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

/* Responsividad (Medium Devices) */
@media screen and (max-width:1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

/* Responsividad (Small Devices) */
@media screen and (max-width:600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}