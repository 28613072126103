.about__container {
  display: grid;
  grid-template-columns: 35% 65%;
  justify-content: center;
  align-items: center;
  /* gap: 5%; */
}

.about__me {
  width: 85%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
  display: grid;
  place-items: center;
}

.about__me-image {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(10deg);
  transition: var(--transition);
}

.about__me-image:hover {
  transform: rotate(0);
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.about__card {
  background: transparent;
  border: 1px solid var(--color-primary-variant);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 3px;
  padding: 1rem;
  text-align: center;
  transition: var(--transition);
}

.about__card:hover {
  color: var(--color-white);
  background: var(--color-bg-variant);
  border-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: default;
  transform: scale(1.1);
}

.about__icon {
  color: var(--color-white);
  font-size: 4rem;
}

.about__card h5 {
  font-size: 2.3rem;
}

.about__card small {
  font-size: 1rem;
  color: var(--color-light);
}

.about__text {
  grid-column: 1/3;
}

.about__text p {
  margin: 1rem 0 2rem;
  text-align: justify;
  font-size: 1.24rem;
  color: var(--color-light);
}

/* Responsividad (Medium Devices) */
@media screen and (max-width:1024px) {
  .about__container {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .about__me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about__text p {
    margin: 1rem 0 1.5rem;
  }

  .about__btn {
    display: flex;
    justify-content: center;
  }

  /* .about__content a {
    width: 30%;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  } */
}

/* Responsividad (Small Devices) */
@media screen and (max-width:600px) {
  .about__me {
    width: 65%;
    margin: 0 auto 3rem;
  }

  .about__cards {
    grid-template-columns:  1fr;
    gap: 1rem;
  }

  .about__content {
    text-align: center;
  }

  .about__content p {
    margin: 1.5rem 0;
  }
}