@import url(https://fonts.googleapis.com/css?family=Lobster:regular);

* {
  font-family: 'Poppins', sans-serif;
}

/*============Sección Header============*/
header {
  height: 100vh;
  overflow: hidden;
}

#header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 14;
}

/*============(Logo y Botones)============*/
#header .logo {
  color: #fff; 
  font-family: 'Lobster', cursive; 
  font-size: 36px; 
  font-weight: normal; 
  line-height: 48px; 
  margin: 0 0 18px;
  padding-top: 20px;
  opacity: 0.3;
}

#header ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

#header ul li {
  list-style: none;
  margin-top: -25px;
  margin-left: 15px;
}

#header ul li a {
  text-decoration: none;
  padding: 6px 15px;
  color: #fff;
  border-radius: 20px;
}

#header ul li a:hover,
#header ul li a.active {
  background: #fff;
  color: #3e2723;
}

/*============(Textos Nombre y Descripcion)============*/
#headerText {
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

#headerText ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 4rem;
  gap: 0.5rem;
}

#headerText ul li {
  list-style: none;
}

#headerText ul li p {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  color: #f5f5f5;
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);

  transform: translateY(35vh);
}

#headerText #name {
  font-size: 6rem;
}

#headerText #desc {
  font-size: 2rem;
}

/*============(Botones de CV y Contacto)============*/
#headerCV {
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

#headerCV ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

#headerCV ul li {
  list-style: none;
}

#headerCV ul li a {
  display: inline-block;
  text-decoration: none;
  padding: 8px 30px;
  border-radius: 40px;
  font-size: 1.5em;
  background: #fff;
  color: #3e2723;
  
  transform: translateY(43vh);
  
}

#headerCV ul li a:hover,
#headerCV ul li a.active {
  background: #3e2723;
  color: #fff;
}

/*============Parallax============*/
#parallax {
  display: block;
}

.keyart {
  position: relative;
  z-index: 10;
}

.keyart, .keyart_layer {
	height: 100vh;
}

/* div#keyart-3 {
  z-index: 10;
} */

.keyart_layer {
  background-position: bottom center;
	background-size: auto 100%;
	background-repeat: repeat-x;
	width: 100%;
	position: absolute;
}

.keyart_layer.parallax {
  position: absolute;
}

/*============(Imagenes para cada Layer)============*/
#keyart-0 {
  background-image: url(../../assets/header/sky.png);
}
#keyart-1 {
  background-image: url(../../assets/header/trees_behind.png);
}
#keyart-2 {
  background-image: url(../../assets/header/trees_front.png);
}
#keyart-3 {
  background-image: url(../../assets/header/rock_front.png);
}

/*============Header::before (difuminado entre Header y About)============*/
header::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to top, #3e2723, transparent);
  z-index: 11;
}

/*============Responsividad (Medium devices)============*/
@media screen and (max-width:1024px) {
  header {
    height: 100vh;
  }

  #headerText #name {
    text-align: center;
    font-size: 4.6rem;
  }
  
  #headerText #desc {
    text-align: center;
    font-size: 1.8rem;
  }

  #header a{
    display: none;
  }
}

/*============Responsividad (Small devices)============*/
@media screen and (max-width:600px) {
  header {
    height: 100vh;
  }

  #headerText #name {
    text-align: center;
    transform: translateY(35vh);
  }
  
  #headerText #desc {
    text-align: center;
    font-size: 1.3rem;
    transform: translateY(35vh);
  }

  #headerCV ul{
    transform: translateY(-5vh);
  }

  #headerCV ul li a {
    font-size: 1.2rem;
  }
}